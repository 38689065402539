/**
 * @generated SignedSource<<fc6ba6b8b97b2d8e4df71e552f70f13f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type container_growthRecommendationsInProjectList_RecommendationsInProjectList$data = {
  readonly jira: {
    readonly projectListViewTemplates: {
      readonly nodes: ReadonlyArray<{
        readonly isProductLicensed: boolean;
        readonly key: string;
        readonly productKey: string;
        readonly templateType: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"card_growthRecommendationsInProjectList_TemplateCard" | "preview_growthRecommendationsInProjectList_TemplatePreview">;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly userPreferences: {
      readonly __id: string;
    } | null | undefined;
  };
  readonly " $fragmentType": "container_growthRecommendationsInProjectList_RecommendationsInProjectList";
};
export type container_growthRecommendationsInProjectList_RecommendationsInProjectList$key = {
  readonly " $data"?: container_growthRecommendationsInProjectList_RecommendationsInProjectList$data;
  readonly " $fragmentSpreads": FragmentRefs<"container_growthRecommendationsInProjectList_RecommendationsInProjectList">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "container_growthRecommendationsInProjectList_RecommendationsInProjectList",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              (v0/*: any*/),
              {
                "kind": "Literal",
                "name": "experimentKey",
                "value": "sidebarExperiment"
              }
            ],
            "concreteType": "JiraProjectListViewTemplateConnection",
            "kind": "LinkedField",
            "name": "projectListViewTemplates",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraProjectListViewTemplateItem",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "kind": "ScalarField",
                      "name": "key"
                    },
                    "action": "THROW",
                    "path": "jira.projectListViewTemplates.nodes.key"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "templateType"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "kind": "ScalarField",
                      "name": "productKey"
                    },
                    "action": "THROW",
                    "path": "jira.projectListViewTemplates.nodes.productKey"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "kind": "ScalarField",
                      "name": "isProductLicensed"
                    },
                    "action": "THROW",
                    "path": "jira.projectListViewTemplates.nodes.isProductLicensed"
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "card_growthRecommendationsInProjectList_TemplateCard"
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "preview_growthRecommendationsInProjectList_TemplatePreview"
                  }
                ]
              }
            ]
          },
          {
            "args": [
              (v0/*: any*/)
            ],
            "concreteType": "JiraUserPreferences",
            "kind": "LinkedField",
            "name": "userPreferences",
            "plural": false,
            "selections": [
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__id"
                  }
                ]
              }
            ]
          }
        ]
      },
      "action": "THROW",
      "path": "jira"
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "ed7a729d18ae30ff07077f4116523d01";

export default node;
