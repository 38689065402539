import React, { useCallback, useMemo, type Dispatch, type SetStateAction } from 'react';
import { useCrossFlow, Journeys } from '@atlassiansox/cross-flow-support';
import { graphql, useFragment } from 'react-relay';
import Lozenge from '@atlaskit/lozenge';
import { Box, Inline, Pressable, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { ProjectType } from '@atlassian/jira-common-constants';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectCreateDrawer } from '@atlassian/jira-project-create-drawer-context';
import type { card_growthRecommendationsInProjectList_TemplateCard$key } from '@atlassian/jira-relay/src/__generated__/card_growthRecommendationsInProjectList_TemplateCard.graphql';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller';
import { useFireCrossFlowAnalyticsEvents } from '@atlassian/surface-analytics';
import {
	PROJECT_CREATE_SOURCE,
	PROJECT_TYPE_TARGETS_MAP,
	getProductTypeCcpReferenceIdMapForEnv,
} from '../../constants';
import { messages } from '../../messages';
import CardIcon from './icon';
import { buildCrossflowExtensions } from './utils';

interface Props {
	dataRef: card_growthRecommendationsInProjectList_TemplateCard$key;
	setTemplatePreviewKey: Dispatch<SetStateAction<string | null>>;
	recommendedProductIds: string[];
}
export const TemplateCard = ({ dataRef, recommendedProductIds, setTemplatePreviewKey }: Props) => {
	const environment = useEnvironment();
	const { fireInteractedUIEvent } = useFireCrossFlowAnalyticsEvents();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const crossFlow = useCrossFlow();

	const {
		methods: { open: openProjectCreate },
	} = useProjectCreateDrawer();

	const cardEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'card',
	});

	const cardHoveredEvent = createAnalyticsEvent({
		action: 'hovered',
		actionSubject: 'card',
	});

	const template = useFragment<card_growthRecommendationsInProjectList_TemplateCard$key>(
		graphql`
			fragment card_growthRecommendationsInProjectList_TemplateCard on JiraProjectListViewTemplateItem {
				title @required(action: THROW)
				shortDescription @required(action: THROW)
				key @required(action: THROW)
				isProductLicensed @required(action: THROW)
				isPremiumOnly @required(action: THROW)
				productKey @required(action: THROW)
				recommendationSessionId @required(action: THROW)
				templateType
				...icon_growthRecommendationsInProjectList_CardIcon
			}
		`,
		dataRef,
	);

	const { formatMessage } = useIntl();

	// Exposure fired in BE
	const [expConfig] = UNSAFE_noExposureExp('cb_jira_template_recs_with_for_all_products');
	const shouldUseNewRecommendations = expConfig.get('useNewRecommendations', false);

	const {
		title,
		shortDescription,
		key,
		isProductLicensed,
		isPremiumOnly,
		productKey,
		templateType,
		recommendationSessionId,
	} = template;

	const extensions = useMemo(
		() =>
			buildCrossflowExtensions({
				templateId: shouldUseNewRecommendations ? templateType ?? key : key,
				recommendationSessionId,
			}),
		[key, recommendationSessionId, shouldUseNewRecommendations, templateType],
	);

	const onClick = useCallback(() => {
		fireUIAnalytics(cardEvent, 'templateCard', {
			key,
			productKey,
			isProductLicensed,
			isPremiumOnly,
			...(shouldUseNewRecommendations ? { templateType, recommendationSessionId } : { extensions }),
		});

		if (
			!isProductLicensed &&
			crossFlow.isEnabled &&
			PROJECT_TYPE_TARGETS_MAP[productKey] &&
			getProductTypeCcpReferenceIdMapForEnv(environment)[productKey]
		) {
			fireInteractedUIEvent(
				{
					action: 'clicked',
					actionSubject: 'card',
					actionSubjectId: 'templateCard',
				},
				{
					recommendedProductIds,
					recommendationSession: {
						id: recommendationSessionId,
						type: 'appRec',
					},
					targetProductId: getProductTypeCcpReferenceIdMapForEnv(environment)[productKey],
					source: 'projectsDirectorySidebarSection',
				},
			);
			crossFlow.api.open({
				journey: Journeys.GET_STARTED,
				targetProduct: PROJECT_TYPE_TARGETS_MAP[productKey],
				sourceComponent: 'projectsDirectorySidebarSection',
				sourceContext: 'templateCard',
				extensions,
			});
			return;
		}

		openProjectCreate({
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			selectedProjectType: productKey.toLowerCase() as ProjectType,
			showExperienceSelection: false,
			defaultSelectedTemplateKey: key,
			source: PROJECT_CREATE_SOURCE,
			...(shouldUseNewRecommendations && { recommendationSessionId }),
		});
	}, [
		cardEvent,
		key,
		productKey,
		isProductLicensed,
		isPremiumOnly,
		shouldUseNewRecommendations,
		templateType,
		recommendationSessionId,
		extensions,
		crossFlow,
		environment,
		openProjectCreate,
		fireInteractedUIEvent,
		recommendedProductIds,
	]);

	const onPreviewDismiss = () => {
		setTemplatePreviewKey((current) => {
			if (current === key) {
				return null;
			}
			return current;
		});
	};

	const onPreviewStart = () => {
		fireUIAnalytics(cardHoveredEvent, 'templateCard', {
			key,
			productKey,
			isProductLicensed,
			templateType,
			isPremiumOnly,
		});
		setTemplatePreviewKey(key);
	};

	return (
		<Box
			as="span"
			xcss={PaddedCardStyles}
			onMouseEnter={onPreviewStart}
			onMouseLeave={onPreviewDismiss}
		>
			<Pressable
				padding="space.200"
				xcss={CardStyles}
				onFocus={onPreviewStart}
				onBlur={onPreviewDismiss}
				onClick={onClick}
				tabIndex={0}
			>
				<Inline space="space.150" alignBlock="center">
					<CardIcon keyRef={template} />
					<Stack as="div" space="space.050">
						<Box as="span" xcss={TitleStyles}>
							{title}{' '}
							{!isProductLicensed && (
								<Lozenge appearance="new">{formatMessage(messages.tryLozenge)}</Lozenge>
							)}
							{isProductLicensed && isPremiumOnly && (
								<Lozenge appearance="new">{formatMessage(messages.premiumLozenge)}</Lozenge>
							)}
						</Box>
						<Box as="p" xcss={DescriptionStyles}>
							{shortDescription}
						</Box>
					</Stack>
				</Inline>
			</Pressable>
		</Box>
	);
};

const PaddedCardStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
	paddingBlock: 'space.100',
});

const CardStyles = xcss({
	width: '100%',
	textAlign: 'left',
	borderRadius: 'border.radius',
	boxShadow: 'elevation.shadow.raised',
	backgroundColor: 'elevation.surface.raised',
	':hover': {
		background: 'elevation.surface.overlay',
		boxShadow: 'elevation.shadow.overlay',
	},
	':focus': {
		background: 'elevation.surface.overlay',
		boxShadow: 'elevation.shadow.overlay',
	},
});

const TitleStyles = xcss({
	font: token('font.body'),
	color: 'color.text',
});

const DescriptionStyles = xcss({
	margin: '0',
	color: 'color.text.subtlest',
	font: token('font.body.UNSAFE_small'),
});
