/**
 * @generated SignedSource<<ea332200bc387852513919290f8d1b3e>>
 * @relayHash a9b5647d8459f16784cadf784c08ee01
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f31dd28adcaf6c18a0dc9713c957a224b1432a8978a87fc1069abdd23c838a83

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraProjectListRightPanelState = "CLOSED" | "OPEN" | "%future added value";
export type ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery$variables = {
  cloudId: string;
  isAnonymous: boolean;
};
export type ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery$data = {
  readonly jira?: {
    readonly canCreateProject: boolean | null | undefined;
    readonly sidebarExperimentTemplates: {
      readonly totalCount: number | null | undefined;
    } | null | undefined;
    readonly userPreferences: {
      readonly projectListRightPanelState: JiraProjectListRightPanelState | null | undefined;
    } | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"container_growthRecommendationsInProjectList_RecommendationsInProjectList">;
};
export type ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery = {
  response: ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery$data;
  variables: ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isAnonymous"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "experimentKey",
    "value": "sidebarExperiment"
  }
],
v4 = {
  "concreteType": "JiraQuery",
  "kind": "LinkedField",
  "name": "jira",
  "plural": false,
  "selections": [
    {
      "args": (v2/*: any*/),
      "concreteType": "JiraUserPreferences",
      "kind": "LinkedField",
      "name": "userPreferences",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "projectListRightPanelState"
        }
      ]
    },
    {
      "alias": "sidebarExperimentTemplates",
      "args": (v3/*: any*/),
      "concreteType": "JiraProjectListViewTemplateConnection",
      "kind": "LinkedField",
      "name": "projectListViewTemplates",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "totalCount"
        }
      ]
    },
    {
      "alias": "canCreateProject",
      "args": [
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "type",
          "value": "CREATE_PROJECT"
        }
      ],
      "kind": "ScalarField",
      "name": "canPerform"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery",
    "selections": [
      {
        "condition": "isAnonymous",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v4/*: any*/),
            "action": "THROW",
            "path": "jira"
          }
        ]
      },
      {
        "kind": "FragmentSpread",
        "name": "container_growthRecommendationsInProjectList_RecommendationsInProjectList"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery",
    "selections": [
      {
        "condition": "isAnonymous",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          (v4/*: any*/)
        ]
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraProjectListViewTemplateConnection",
            "kind": "LinkedField",
            "name": "projectListViewTemplates",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraProjectListViewTemplateItem",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "key"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "templateType"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "productKey"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "isProductLicensed"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "title"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "shortDescription"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "isPremiumOnly"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "recommendationSessionId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "iconUrl"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "iconDarkUrl"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "description"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "previewUrl"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "previewDarkUrl"
                  }
                ]
              }
            ]
          },
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraUserPreferences",
            "kind": "LinkedField",
            "name": "userPreferences",
            "plural": false,
            "selections": [
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__id"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "f31dd28adcaf6c18a0dc9713c957a224b1432a8978a87fc1069abdd23c838a83",
    "metadata": {},
    "name": "ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "fc55adfe7ee5532c212fa2d7dc482641";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
