import {
	Behaviours,
	createProvisioningPageExtensions,
	createRecommendationContextExtensions,
	Prompts,
	TargetTabs,
} from '@atlassiansox/cross-flow-extensions';

export const buildCrossflowExtensions = (args: {
	templateId: string;
	recommendationSessionId: string;
}) => {
	const { templateId, recommendationSessionId } = args;
	const provisioningExtensions = createProvisioningPageExtensions({
		actions: {
			primary: {
				behaviour: Behaviours.LAND,
				prompt: Prompts.NEXT,
				options: {
					targetTab: TargetTabs.SAME,
					urlParams: { templateId },
				},
			},
		},
	});

	const recoExtensions = createRecommendationContextExtensions({
		recommendationSessionId,
		entityId: templateId,
	});
	const mergedExtensions = {};
	Object.assign(mergedExtensions, provisioningExtensions, recoExtensions);
	return mergedExtensions;
};
