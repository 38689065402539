/* eslint-disable jira/import/no-parent-imports */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { ExitingPersistence, FadeIn } from '@atlaskit/motion';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	DRAWER,
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useProjectCreateDrawer } from '@atlassian/jira-project-create-drawer-context';
import type { container_growthRecommendationsInProjectList_RecommendationsInProjectList$key } from '@atlassian/jira-relay/src/__generated__/container_growthRecommendationsInProjectList_RecommendationsInProjectList.graphql';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller';
import { useFireCrossFlowAnalyticsEvents } from '@atlassian/surface-analytics';
import { PROJECT_CREATE_SOURCE, getProductTypeCcpReferenceIdMapForEnv } from '../../../constants';
import { messages } from '../../../messages';
import { useSetProjectListRightPanelState } from '../../../services';
import { TemplateCard } from '../../card';
import { TemplatePreview } from '../../preview';
import { JiraProjectListSidePanelSkipLink } from '../../skip-link';

type Props = {
	dataRef: container_growthRecommendationsInProjectList_RecommendationsInProjectList$key;
};

const RecommendationsInProjectList = ({ dataRef }: Props) => {
	const [templatePreviewKey, setTemplatePreviewKey] = useState<string | null>(null);
	const environment = useEnvironment();
	const { fireViewedUIEvent } = useFireCrossFlowAnalyticsEvents();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const {
		methods: { open: openProjectCreate },
	} = useProjectCreateDrawer();
	const buttonEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'button',
	});
	const { formatMessage } = useIntl();

	const { jira } =
		useFragment<container_growthRecommendationsInProjectList_RecommendationsInProjectList$key>(
			graphql`
				fragment container_growthRecommendationsInProjectList_RecommendationsInProjectList on Query {
					jira @required(action: THROW) {
						projectListViewTemplates(cloudId: $cloudId, experimentKey: "sidebarExperiment")
							@optIn(to: "JiraProjectListViewTemplate") {
							nodes {
								key @required(action: THROW)
								templateType
								productKey @required(action: THROW)
								isProductLicensed @required(action: THROW)
								...card_growthRecommendationsInProjectList_TemplateCard
								...preview_growthRecommendationsInProjectList_TemplatePreview
							}
						}
						userPreferences(cloudId: $cloudId) @optIn(to: "JiraUserPreferences") {
							__id
						}
					}
				}
			`,
			dataRef,
		);

	const setProjectListRightPanelState = useSetProjectListRightPanelState(
		jira?.userPreferences?.__id,
	);

	const onCloseClick = useCallback(() => {
		fireUIAnalytics(buttonEvent, 'closeButton');
		setProjectListRightPanelState('CLOSED');
	}, [buttonEvent, setProjectListRightPanelState]);

	const templates = useMemo(
		() =>
			jira.projectListViewTemplates?.nodes?.filter(
				<T,>(template: T | undefined | null): template is T => !!template,
			) ?? [],
		[jira.projectListViewTemplates?.nodes],
	);

	const templatePreviewRef = useMemo(
		() => templates.find((template) => template.key === templatePreviewKey),
		[templatePreviewKey, templates],
	);

	const recommendedProductIds = useMemo(
		() =>
			Array.from(
				templates.reduce((acc, template) => {
					const { isProductLicensed, productKey } = template;
					if (!isProductLicensed) {
						acc.add(getProductTypeCcpReferenceIdMapForEnv(environment)[productKey]);
					}
					return acc;
				}, new Set<string>()),
			),
		[environment, templates],
	);

	const onMoreTemplatesClick = useCallback(() => {
		fireUIAnalytics(buttonEvent, 'moreTemplatesButton');
		openProjectCreate({ showExperienceSelection: false, source: PROJECT_CREATE_SOURCE });
	}, [buttonEvent, openProjectCreate]);

	useEffect(() => {
		recommendedProductIds.length > 0 &&
			fireViewedUIEvent(
				{
					action: 'viewed',
					actionSubject: 'section',
					actionSubjectId: 'projectsDirectorySidebar',
				},
				{
					recommendedProductIds,
					source: 'projectsDirectorySidebarSection',
				},
			);
	}, [fireViewedUIEvent, recommendedProductIds]);

	// Exposure fired in BE
	const [expConfig] = UNSAFE_noExposureExp('cb_jira_template_recs_with_for_all_products');
	const shouldUseNewRecommendations = expConfig.get('useNewRecommendations', false);

	return (
		<Box id="jira-project-list-side-panel" xcss={SidebarContainerStyles}>
			<Stack xcss={ExperimentSidebarContentStyles}>
				<Stack>
					<Inline xcss={TitleStyles} alignBlock="center" spread="space-between">
						<Heading level="h100">{formatMessage(messages.title)}</Heading>
						<Box xcss={ButtonBoxStyles}>
							<Button
								iconBefore={<CrossIcon label="" />}
								aria-label={formatMessage(messages.closeButton)}
								appearance="subtle"
								onClick={onCloseClick}
							/>
						</Box>
					</Inline>
					<Box as="p" xcss={SubtitleStyles}>
						{formatMessage(messages.subtitle)}
					</Box>
				</Stack>
				{templates.map((cardRef) => (
					<TemplateCard
						key={cardRef.key}
						dataRef={cardRef}
						recommendedProductIds={recommendedProductIds}
						setTemplatePreviewKey={setTemplatePreviewKey}
					/>
				))}
				<Inline>
					<Button
						appearance="link"
						spacing="none"
						label={formatMessage(messages.moreTemplatesLink)}
						onClick={() => onMoreTemplatesClick()}
					>
						{messages.moreTemplatesLink && formatMessage(messages.moreTemplatesLink)}
					</Button>
				</Inline>
			</Stack>
			<FireScreenAnalytics
				attributes={{
					templates: templates.map(({ key }) => key),
					templateTypes: shouldUseNewRecommendations
						? templates.map(({ templateType }) => templateType)
						: undefined,
				}}
			/>
			<JiraProjectListSidePanelSkipLink />
			<JSErrorBoundary
				id="template-preview-wrapper"
				packageName="jiraGrowthRecommendationsInProjectList"
			>
				<ExitingPersistence>
					{templatePreviewRef && (
						<FadeIn duration={1000}>
							{(fadeInProps) => (
								<div {...fadeInProps}>
									<TemplatePreview previewRef={templatePreviewRef} />
								</div>
							)}
						</FadeIn>
					)}
				</ExitingPersistence>
			</JSErrorBoundary>
		</Box>
	);
};

const RecommendationsInProjectListContainer = (props: Props) => (
	<ContextualAnalyticsData sourceName="projectListTemplates" sourceType={DRAWER}>
		<RecommendationsInProjectList {...props} />
	</ContextualAnalyticsData>
);

const TitleStyles = xcss({
	textTransform: 'uppercase',
	color: 'color.text.subtle',
	position: 'relative',
});

const ButtonBoxStyles = xcss({
	paddingInline: 'space.050',
});

const SubtitleStyles = xcss({
	color: 'color.text.subtlest',
	font: token('font.body'),
	marginTop: 'space.0',
});

const ExperimentSidebarContentStyles = xcss({
	height: '100%',
	overflow: 'auto',
	width: '360px',
	padding: 'space.300',
	backgroundColor: 'elevation.surface.sunken',
	boxSizing: 'border-box',
});

const SidebarContainerStyles = xcss({
	height: '100%',
});

export default RecommendationsInProjectListContainer;
