import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Image from '@atlaskit/image';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import {
	UNSAFE_DO_NOT_USE_BANNER_HEIGHT,
	UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT,
} from '@atlassian/jira-navigation-system';
import type { preview_growthRecommendationsInProjectList_TemplatePreview$key } from '@atlassian/jira-relay/src/__generated__/preview_growthRecommendationsInProjectList_TemplatePreview.graphql';
import { SIDEBAR_WIDTH } from '../../constants';

interface Props {
	previewRef: preview_growthRecommendationsInProjectList_TemplatePreview$key;
}

export const TemplatePreview = ({ previewRef }: Props) => {
	const left = document.getElementById('ak-main-content')?.getBoundingClientRect().left ?? 0;
	const TemplatePreviewWrapperStyles = xcss({
		position: 'absolute',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `calc(100vw - ${SIDEBAR_WIDTH}px - ${left}px)`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		height: `calc(100vh - ${UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT} - ${UNSAFE_DO_NOT_USE_BANNER_HEIGHT})`,
		backgroundColor: 'elevation.surface',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		top: 'space.0',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		right: `${SIDEBAR_WIDTH}px`,
		pointerEvents: 'none',
		zIndex: 'spotlight',
		':empty': {
			display: 'none',
		},
		overflow: 'hidden',
	});

	const { title, description, key, previewUrl, previewDarkUrl } =
		useFragment<preview_growthRecommendationsInProjectList_TemplatePreview$key>(
			graphql`
				fragment preview_growthRecommendationsInProjectList_TemplatePreview on JiraProjectListViewTemplateItem {
					title @required(action: THROW)
					description @required(action: THROW)
					key @required(action: THROW)
					previewUrl @required(action: THROW)
					previewDarkUrl @required(action: THROW)
				}
			`,
			previewRef,
		);

	return (
		<Box xcss={TemplatePreviewWrapperStyles}>
			<TemplateAreaContainer>
				<Stack xcss={PreviewWrapperStyles} space="space.500">
					<Stack space="space.100" xcss={TextContainerStyles}>
						<Box as="span" xcss={TitleStyles}>
							{title}
						</Box>
						<Box as="p" xcss={DescriptionStyles}>
							{description}
						</Box>
					</Stack>
					<Box as="span" xcss={ImageWrapperStyles}>
						<PreviewImage src={previewUrl} key={key} alt="" srcDark={previewDarkUrl} />
					</Box>
				</Stack>
			</TemplateAreaContainer>
		</Box>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplateAreaContainer = styled.span({
	width: '75%',
	'@media (min-aspect-ratio: 11 / 6)': { maxWidth: '50%' },
});

const ImageWrapperStyles = xcss({
	aspectRatio: '105/62',
});

const TextContainerStyles = xcss({
	color: 'color.text',
});

const TitleStyles = xcss({
	font: token('font.heading.xlarge'),
	fontWeight: token('font.weight.regular'),
});

const DescriptionStyles = xcss({
	font: token('font.body'),
	margin: '0',
});

const PreviewWrapperStyles = xcss({
	backgroundColor: 'elevation.surface',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PreviewImage = styled(Image)({
	objectFit: 'contain',
	objectPosition: 'top',
	width: '100%',
});
