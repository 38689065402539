/**
 * @generated SignedSource<<de4610ad930fc5fe7db753035ec004b5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type card_growthRecommendationsInProjectList_TemplateCard$data = {
  readonly isPremiumOnly: boolean;
  readonly isProductLicensed: boolean;
  readonly key: string;
  readonly productKey: string;
  readonly recommendationSessionId: string;
  readonly shortDescription: string;
  readonly templateType: string | null | undefined;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"icon_growthRecommendationsInProjectList_CardIcon">;
  readonly " $fragmentType": "card_growthRecommendationsInProjectList_TemplateCard";
};
export type card_growthRecommendationsInProjectList_TemplateCard$key = {
  readonly " $data"?: card_growthRecommendationsInProjectList_TemplateCard$data;
  readonly " $fragmentSpreads": FragmentRefs<"card_growthRecommendationsInProjectList_TemplateCard">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "card_growthRecommendationsInProjectList_TemplateCard",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "title"
      },
      "action": "THROW",
      "path": "title"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "shortDescription"
      },
      "action": "THROW",
      "path": "shortDescription"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "key"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "isProductLicensed"
      },
      "action": "THROW",
      "path": "isProductLicensed"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "isPremiumOnly"
      },
      "action": "THROW",
      "path": "isPremiumOnly"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "productKey"
      },
      "action": "THROW",
      "path": "productKey"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "recommendationSessionId"
      },
      "action": "THROW",
      "path": "recommendationSessionId"
    },
    {
      "kind": "ScalarField",
      "name": "templateType"
    },
    {
      "kind": "FragmentSpread",
      "name": "icon_growthRecommendationsInProjectList_CardIcon"
    }
  ],
  "type": "JiraProjectListViewTemplateItem"
};

(node as any).hash = "a3a64269e13340446cb811b0ad3c2748";

export default node;
